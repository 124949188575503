import { nextTick } from 'vue'
import { dealProductsPretreatInfo } from 'public/src/services/goodsItemInfo/goodsPretreatInfo.js'

const SLICE_PUSH_INIT_COUNT = 40 // 初始化水合后每次push商品数量
const SLICE_PUSH_COUNT = 5       // 筛选、翻页每次push商品数量

function safeRequestAnimationFrame(callback) {
  if (typeof window !== 'undefined' && typeof window.requestAnimationFrame !== 'undefined') {
    return window.requestAnimationFrame(callback)
  } else {
    // 如果在服务器端环境，使用一个兼容的降级方案
    return setTimeout(callback, 16)
  }
}

/**
 * @description 分割push商品数据
 * @param {Array} goods - 商品数据
 * @param {Number} splitNum - 分割数量
 * @param {Function} callback - 回调函数
 * */ 
const handleSplitGoods = (goods, splitNum, callback = () => {}, endCallBack = () => {}) => {
  if (!goods?.length) return
  const handleGoods = (arr, num) => {
    if (!arr?.length) {
      return safeRequestAnimationFrame(async () => {
        await nextTick()

        const daEventExpose = window?.daEventCenter?.getExposeInstance()
        if (daEventExpose) {
          setTimeout(() => {
            daEventExpose.update('productListExposeCode')
          }, 2000)
        }
        
        endCallBack?.()
      })
    }
    const goodsChunk = arr.slice(0, num)
    safeRequestAnimationFrame(() => {
      callback(goodsChunk)
      handleGoods(arr.slice(num), num)
    })
  }
  const initArr = goods.slice(0, splitNum)
  callback(initArr)
  goods = goods.slice(splitNum)
  handleGoods(goods, splitNum)
}

const mutations = {
  jumpUrl(state, { localUrl = '', queryObj = {}, isClickAttr = false }) {
    const url = localUrl ? localUrl : location.origin + location.pathname
    if (!isClickAttr) {
      delete queryObj.exc_attr_id
    }
    if (state?.results?.searchKeywords?.double_lang_correct) {
      queryObj.force_suggest = 1
    }
    const queryString = Object.keys(queryObj)
      .reduce((str, query) => {
        const value = queryObj[query]
        const needRemainZeroValue = ['min_price', 'max_price', 'sourceStatus'].includes(query) && Number(value) === 0
        if (value || needRemainZeroValue) {
          // attr_values前置
          if (query == 'attr_values') {
            str = str.replace(
              '?',
              `?${query}=${encodeURIComponent(queryObj[query])}&`
            )
          } else {
            str += `${query}=${encodeURIComponent(queryObj[query])}&`
          }
        }
        return str
      }, '?')
      .slice(0, -1)
    
    // 筛选后，滚动复位逻辑
    if (state.isSticky) {
      const scrollFixSubHeight = Array.from(document.querySelectorAll('.scrollfix-sub')).reduce(
        (curr, next) => (curr += next.offsetHeight),
        0
      )
        
      window.scrollTo(0, scrollFixSubHeight)
    }

    history.replaceState(null, '', url + queryString)
  },
  assignState(state, payload) {
    Object.assign(state, payload)
  },
  resetState(state, { payload }) {
    if (payload.language) state.Language = payload.language
  },
  dealingResults(state, payload) {
    const results = payload?.results || {}
    const { cat_info } = results
    const requestType = cat_info.requestType
    if (requestType == 'refresh') {
      Object.keys(results).forEach(key => {
        state.results[key] = results[key]
      })
    } else if (requestType == 'pageChange') {
      state.results.cat_info = cat_info || {}
    }
  },
  dealingFilterAttr (state, payload) { 
    const { Results } = state
    const { staticFilterId = '', staticFilterType, filterAttrs: oldFilterAttrs = [], filterTsps: oldFilterTsps = [] } = Results || {}
    const { cat_info = {}, filterAttrs: newFilterAttrs = [], filterTsps: newFilterTsps = [] } = payload?.results || {}
    const requestType = cat_info.requestType
    if (requestType == 'pageChange') return

    if (!newFilterAttrs?.length || !staticFilterId) {
      Results.filterAttrs = newFilterAttrs
      Results.filterTsps = newFilterTsps
      return
    }
    
    if (staticFilterType == 'attr') {
      const oldIndex = oldFilterAttrs.findIndex(attr => attr.attr_id == staticFilterId)
      const newIndex = newFilterAttrs.findIndex(attr => attr.attr_id == staticFilterId);
      (oldIndex > -1 && newIndex > -1) && (newFilterAttrs[newIndex] = oldFilterAttrs[oldIndex])
    } else if (staticFilterType == 'tsp') {
      const oldIndex = oldFilterTsps.findIndex(tsp => tsp.tagGroupId == staticFilterId)
      const newIndex = newFilterTsps.findIndex(tsp => tsp.tagGroupId == staticFilterId);
      (oldIndex > -1 && newIndex > -1) && (newFilterTsps[newIndex] = oldFilterTsps[oldIndex])
    }
    Results.filterAttrs = newFilterAttrs
    Results.filterTsps = newFilterTsps
  },
  /**
   * @description 商品列表数据处理
   * */ 
  dealingGoods({ Results, Language, listAbtResult }, payload) {
    let { goods = [], promotionInfoFromServer = {}, cat_info } = payload?.results || {}
    // 新的商品预处理服务 - 目前只有促销
    goods = dealProductsPretreatInfo({
      products: goods, 
      promotionInfoFromServer, 
      language: Language,
    })
    if (cat_info.isPageLoadMore) { // 小分页逻辑，push商品
      Results.goods.push(...goods)
      return
    }
    const isSliceRender = typeof window !== 'undefined' && listAbtResult?.ProductListStrategyRender?.p?.open === 'yes' // 是否开启分片渲染
    const requestType = cat_info.requestType
    if (isSliceRender && ['refresh', 'pageChange'].includes(requestType)) { // 筛选和翻页进行拆分时间处理
      Results.goods = []
      handleSplitGoods(goods, SLICE_PUSH_COUNT, (arr) => {
        Results.goods.push(...arr)
      }, payload.endCallBack)
    } else {
      Results.goods = goods
    }
  },

  dealingSplitGoods({ Results, Language }, payload) {
    const { goods, promotionInfoFromServer = {}, slicePushCount = SLICE_PUSH_INIT_COUNT, endCallBack } = payload
    const list = dealProductsPretreatInfo({
      products: goods, 
      promotionInfoFromServer, 
      language: Language,
    })
    handleSplitGoods(list, slicePushCount, (arr) => {
      Results.goods.push(...arr)
    }, endCallBack)
  }
}

export default mutations
